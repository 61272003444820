import {transformNames} from '@/utils/helpers'
export const mapRouters = (clazz,beanName,scope) => {
    let names = transformNames(clazz),
        contextPath = ['.',scope,names.plural].join('/')
    return [
        {path: '/', component: () => import(`${contextPath}/index.vue`), name: `${beanName}管理`, alias: [scope,names.plural].join('_')},
        {path: '/new', component: () => import(`${contextPath}/new.vue`), name: `新建${beanName}`,alias: [scope,names.plural,'new'].join('_')},
        {path: '/:id/edit', component: () => import(`${contextPath}/edit.vue`), name: `修改${beanName}`, alias: [scope,names.single,'edit'].join('_')},
        {path: '/:id/show', component: () => import(`${contextPath}/show.vue`), name: `${beanName}详情`,alias: [scope, names.single].join('_')},
    ]
}