import ajax from '@/requests/ajax'
import {env} from '@/configs.js'

export default {
    putObject(file,prefix){
        let {name, type} = file;
        return new Promise((res, rej) => {
            ajax.post(env.url('/resources/direct_upload'),{name,prefix}).then(resp =>{
                let url = resp.data.url,
                    path = url.split('?')[0]
                ajax.put(url, file, {headers:{'Content-Type' : type}}).then(resp => {
                    console.log('file upload result: ', resp)
                    if(resp.status == 200){
                        res({name, type, file, url: path})
                    }else{
                        console.error('Upload file with server exception')
                        rej(resp)
                    }
                }).catch(e => {
                    console.error('File upload failed!', e)
                    rej(e)
                })
            })
        })
    }
}