import ajax from '@/requests/ajax'
import {env} from '@/configs.js'

const context = '/resources/materials'
export default {
    getStores(search){
        return new Promise((resolve, reject) => {
            ajax.get(
                env.url(`${context}/stores.json`),
                {params: search}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    getFolderResources(storeId,ids,search){
        return new Promise((resolve, reject) => {
            ajax.get(
                env.url(`${context}/s/${storeId}/fv${ids.length > 0 ? '/'+ids.join('-') : ''}.json`),
                {params: search}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    getMaterialResourcesByTags(storeId, tags){
        return new Promise((resolve, reject) => {
            ajax.get(
                env.url(`${context}/s/${storeId}/tags.json`),
                {params: {tags: JSON.stringify(tags)}}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    createFolder(storeId,parentId,data){
        return new Promise((resolve, reject) => {
            ajax.put(
                env.url(`${context}/s/${storeId}/f/${parentId ? parentId : ''}`),
                data
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    deleteFolder(storeId,folderId){
        return new Promise((resolve, reject) => {
            ajax.delete(
                env.url(`${context}/s/${storeId}/f/${folderId}`),
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    renameFolder(storeId, folderId, name){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/s/${storeId}/f/${folderId}/rename/`),
                {name}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    addResources(storeId, folderId, items){
        return new Promise((resolve, reject) => {
            ajax.put(
                env.url(`${context}/s/${storeId}/f/${folderId}/resource/`),
                {items}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    moveToFolder(storeId,folderId,resIds){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/s/${storeId}/f/${folderId}/move/`),
                {res_ids: resIds}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    }
}