import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/audios/providers'
export default {
    ...mapApis(context),
    getProviderVoices(provider,form){
        return ajax.get(env.url(`${context}/${provider}.json`),{params: form})
    },
    playDemo(provider,voice){
        return ajax.post(env.url(`${context}/${provider}/voices/${voice}.json`))
    },
    getTaskInfo(provider,taskId){
        return ajax.post(env.url(`${context}/${provider}/tasks.json`),{task_id: taskId})
    },
    pickVoice(provider,voice){
        return ajax.post(env.url(`${context}/${provider}/voices.json`),voice)
    },
    unpickVoice(provider,voiceId){
        return ajax.post(env.url(`${context}/${provider}/voices/${voiceId}/disable.json`))
    },
}