import requests from '@/requests/requests.js'
import {mapBaseStates,mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
const STATUS_MAP = {null: '全部', 0: '未读', 1: '已读'},
    MSG_TYPE_MAP = {null: '全部', assist: '系统小助手', system: '系统通知', notice: '温馨提醒'};

const api = requests.messages.user_messages,
      name = 'user_message'
export default {
    name: 'user_messages',
    state: {
        ...mapBaseStates(null,{
            customer: {},
        })
    },
    getters:{
        ...mapBaseGetters(name),
        userMessageStatusMap: () => STATUS_MAP,
        userMessageMsgTypeMap: () => MSG_TYPE_MAP,
    },
    mutations:{
        ...mapBaseMutations(name, data => {
            data.status_name = STATUS_MAP[data.status]
            data.msg_type_name = MSG_TYPE_MAP[data.msg_type]
            return data
        }),
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}