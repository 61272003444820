<template>
    <router-view v-if="blankPage"></router-view>
    <div class="app d-flex flex-column" v-else>
        <NavBar/>
        <div class="d-flex flex-row flex-grow-1">
            <SideBar :current="currentPath"/>
            <router-view class="flex-grow-1 p-0"></router-view>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/navbar.vue'
import SideBar from '@/components/sidebar.vue'
export default {
    name: 'App',
    computed:{
        currentPath(){
            return this.$store.state.currentPath;
        },
        blankPage(){
            return this.$store.state.blankPage;
        },
    },
    components:{
        NavBar, SideBar
    },
    created(){
    }
}
</script>
<style>
*{
    font-size: 12px;
}
.app{
    width:100vw;
    height:100vh;
}
.disp-row{
    margin-bottom: 5px;
}
.disp-row label{
    width:100%;
    background: #eee;
    font-weight:bold;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
}
 .disp-row .value{
    padding:5px;
    width:100%;
}

.required_field:before{
   content: '*';
   color: red;
   position: relative;
   left: -5px;
}

.tag{
    border-radius: 10px;
    background: #009cad;
    color: white;
    font-size:10px;
    padding: 3px 10px;
    margin-right: 5px;
    cursor: pointer;
}

.custom-select option{
    padding: 50px 0px;
}

pre{
    font-family: Arial, Arial Black
}
pre.preview{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/** override vue-instant */
.vue-instant__suggestions {
    z-index: 1000;
}
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.multiselect__voice__avatar{
    width:50px;
    height: 50px;
}

.multiselect__voice__title{
    font-size: 12pt;
}


.multiselect__voice__desc{
    font-size: 10pt;
    color: #aaa;
    margin-top: 10px;
}

.multiselect__option--highlight .multiselect__voice__desc{
    color: white;
}

</style>