import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/videos/artifacts'
export default {
    ...mapApis(context),
    uploadTempVideo(id){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/${id}/upload/`)
            ).then(resolve).catch(reject)
        })
    },
    removeTempVideo(id){
        return new Promise((resolve, reject) => {
            ajax.delete(
                env.url(`${context}/${id}/tempvideo/`)
            ).then(resolve).catch(reject)
        })
    },
    regen(id,force){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/${id}/regen/`),
                {force}
            ).then(resolve).catch(reject)
        })
    },
    status(id,toStatus){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/${id}/status/${toStatus}/`)
            ).then(resolve).catch(reject)
        })
    }
}