import Vue from 'vue'
import Vuex from 'vuex'
import powerimgs from './modules/powerimgs/index.js'
import messages from './modules/messages/index.js'
import contents from './modules/contents/index.js'
import crm from './modules/crm/index.js'
import resources from './modules/resources/index.js'
import audios from './modules/audios/index.js'
import tasks from './modules/tasks/index.js'
import system from './modules/system/index.js'
import videos from './modules/videos/index.js'
import requests from '@/requests/requests.js'
Vue.use(Vuex)
export default new Vuex.Store({
    state(){
        return {
            currentPath: '/',
            blankPage: false,
        }
    },
    getters:{
    },
    mutations:{
        setCurrentPath(state,path){
           state.currentPath = path
        },
        setBlankPage(state,isBlank){
           state.blankPage = isBlank
        },
    },
    actions:{
        deleteFiles(context, urls){
            requests.deleteFiles(urls)
        }
    },
    modules:{
        contents,
        powerimgs,
        resources,
        messages,
        crm,
        videos,
        audios,
        tasks,
        system
    }
})