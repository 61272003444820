import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseActions,mapBaseMutations} from '../base'

const api = requests.powerimgs.power_images,
      name = 'power_image';

const POWER_IMAGE_STATUS = {
    0: '待完成',
    1: '已分配',
    10: '待审核',
    11: '审核不通过',
    20: '待交付',
    21: '已交付',
    22: '已退回',
}

const SEARCH_STATUS = {all:'全部',incomplete:'待完成',approval:'待审核',done: '已完成',rejected:'已退回'}
const POWER_IMAGE_CATALOG = {
    morning: '早安图',
    product: '产品图'
}

export default {
    name: 'power_images',
    state: {
        ...mapBaseStates({
                search: null,
                customer: null,
                status_name: 'all',
                brand: null,
                assigned_to: null,
                approved_by: null,
                catalog: null,
                plan_delivered_at_max: null,
                plan_delivered_at_min: null,
            }),
        statusStats: {},
    },
    getters:{
        ...mapBaseGetters(name),
        powerImageSearchStatus(){
            return SEARCH_STATUS
        },
        powerImageStatusStats(state){
            return state.statusStats
        }
    },
    mutations:{
        ...mapBaseMutations(name,item => {
            item.status_name = POWER_IMAGE_STATUS[item.status]
            item.catalog_name = POWER_IMAGE_CATALOG[item.catalog]
            return item
        }),
        setPowerImageStatusStats(state,data){
            state.statusStats = data.data
        }
    },
    actions: {
        ...mapBaseActions(name,api),
        /** 审批通过操作 */
        acceptPowerImage(context,{id,comment}){
            return api.triggerStatus(id,'approved', comment)
        },
        /** 审批不通过操作 */
        rejectPowerImage(context,{id,comment}){
            return api.triggerStatus(id,'rejected',comment)
        },
        /** 完成作品操作 */
        donePowerImage(context,{id,comment}){
            return api.triggerStatus(id,'done',comment)
        },
        assignPowerImage(context,{id,assignee_id}){
            return api.assign(id,assignee_id)
        },
        async getPowerImageStatusStats({commit},params){
            let data = await api.statusStats(params)
            commit('setPowerImageStatusStats',data)
            return data
        }
    }
}