import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
import {CUSTOMER_STATUS_MAP, CUSTOMER_STATUS_ORDER} from './customers'

const api = requests.crm.stores,
      name = 'store'
export default {
    name: 'stores',
    state: {
        ...mapBaseStates(
            /** search */
            {
                search: '',
                owner: null,
                status: null
            },
            //item
            {
                owner: {}
            }),
        statusStats: {}
    },
    getters:{
        ...mapBaseGetters(name),
        storeStatusMap: () => CUSTOMER_STATUS_MAP,
        storeStatusOrder: () => CUSTOMER_STATUS_ORDER,
        storeStatusStats: (state) => state.statusStats ,
    },
    mutations:{
        ...mapBaseMutations(name,(data) => {
            data.status_name = CUSTOMER_STATUS_MAP[data.status]
            return data
        }),
        setStoreStatusStats: (state,data) => state.statusStats = data
    },
    actions: {
        ...mapBaseActions(name,api),
        getStoreStatusStats: async({commit},params) => {
            let data = await api.getStatusStats(params)
            commit('setStoreStatusStats', data.data)
            return data
        },
        storeApplicationApproval: async({commit}, {action, id,form}) => {
            let data = await api.approval(action,id, form);
            commit('setStore', data.data)
            return data
        }
    }
}