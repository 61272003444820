import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseActions,mapBaseMutations} from '../base'

const PLATFORMS = {
    wxapp: '微信小程序'
}

const api = requests.crm.oauth_accounts,
      name = 'oauth_account'
export default {
    name: 'oauth_accounts',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name,(item) => {
            item.platform_name = PLATFORMS[item.platform]
            return item
        })
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}