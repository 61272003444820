import ajax from '@/requests/ajax'
import {env} from '@/configs'
export default {
    currentUser(){
        let user = localStorage.getItem('__user');
        return user ? JSON.parse(user) : null;
    },
    clearUser(){
        localStorage.removeItem('__user');
        localStorage.removeItem('__accessToken');
    },
    logout(){
        return new Promise((res) => {
            ajax.delete(env.url('/system/logout')).then(() =>{
                this.clearUser()
                res()
            }).catch(() => {})
        })
    }
}