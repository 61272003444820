import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/crm/brands'
export default {
    ...mapApis(context),
    async getCustomerBrands(id,page){
        return ajax.get(
            env.url(context+'.json'),
            {params: {owner_id: id,page}}
        )
    },
    async getBrandStatusStats(params){
        return ajax.get(
            env.url(context, `/status_stats/`),
            {params}
        )
    },
    async approval(action,id,form){
        return ajax.post(
            env.url(context, `/${id}/${action}/`),
            form
        )
    },
}