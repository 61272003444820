import requests from '@/requests/requests.js'

const api = requests.resources.materials;
const resApi = requests.resources.resources;
export default {
    name: 'materials',
    state: {
        folders: {},
        folder: {tags: []},
        folderSearch: {search: ''},
        folderTree: [],
    },
    getters:{
        folders: state => state.folders,
        folder: state => state.folder,
        folderTree: state =>  state.folderTree,
        folderSearch: state => state.folderSearch,
    },
    mutations:{
        setFolderTree: (state,data) => state.folderTree = data,
        setFolders: (state,data) => state.folders = data,
        setFolder: (state,data) => state.folder = data,
        setFolderSearch: (state,data) => state.search = data,
        setResources: (state,data) => state.resources = data,
    },
    actions: {
        getMaterialStores: async ({commit},search) => {
            let resp = await api.getStores(search);
            commit(`setStores`,resp.data);
            return resp;
        },
        getFolder: async ({commit}, id) => {
            let resp = await resApi.getFolder(id);
            commit(`setFolder`, resp.data);
            return resp;
        },
        /** 给文件夹打标签 */
        setFolderTags: async (context, {id,tags}) => {
            return await resApi.tagFolder(id,tags);
        },
        getMaterialFolderResources: async ({commit},{storeId,ids,search}) => {
            return new Promise((resolve,reject) => {
                api.getFolderResources(storeId, ids, search).then(function(resp){
                    let {folders,resources, store,tree} = resp.data;
                    commit(`setFolders`,folders || []);
                    commit(`setFolderTree`,tree || []);
                    if(tree && tree.length > 0 ){
                        let current = tree[tree.length-1]
                        commit(`setFolder`, current)
                        console.log('set folder ->', current)
                    }else{
                        commit(`setFolder`, null)
                    }
                    commit(`setResources`,resources || []);
                    commit(`setStore`,store || {});
                    resolve(resp);
                }).catch(reject)
            })
        },
        getMaterialResourcesByTags: async (context, {storeId, tags}) => {
            return await api.getMaterialResourcesByTags(storeId, tags);
        },
        addMaterialResources: async(context, {storeId,folderId,items}) => {
            return await api.addResources(storeId, folderId, items);
        },
        addFolder: async({commit,state}, {storeId, folderId,name})  =>  {
            let resp = await api.createFolder(storeId, folderId,{name}),
                folder = resp.data,
                folders = state.folders;
            folders.push(folder);
            commit(`setFolders`,folders)
            return resp;
        },
        /**
         * 删除目录
         */
        destroyFolder: async(context, {storeId, folderId}) => {
            return await api.deleteFolder(storeId, folderId);
        },
        /**
         * 重命名文件夹
         */
        renameFolder: async(context, {storeId,folderId, name}) => {
            return await api.renameFolder(storeId,folderId, name)
        },
        moveToFolder: async(context, {storeId, folderId, resIds}) => {
            return await api.moveToFolder(storeId,folderId, resIds)
        }
    }
}