<template>
    <!-- modal start -->
    <b-modal :visible="visible" title="更改密码" ok-title="修改密码" cancel-title="关闭" @ok.prevent="onChangePassword" @change="changeShow">
        <form class="form" @submit.prevent="onSubmit">
            <div class="form-group">
                <label class="form-label">新密码</label>
                <div class="input-group">
                    <input :type="password.type" class="form-control" placeholder="4位新密码，英文+数字+特殊字符" v-model="password.value" @change="checkPasswordForm" @keyup="checkPasswordForm"/>
                    <div class="input-group-append">
                        <b-button variant="outline-secondary" :pressed="password.show"><b-icon :icon="password.icon" @click="togglePasswordShow"/></b-button>
                    </div>
                </div>
                <small class="text-danger" v-if="errors.password">{{errors.password}}</small>
            </div>
            <div class="form-group">
                <label class="form-label">确认密码</label>
                <div class="input-group">
                    <input :type="confirmpwd.type" class="form-control" placeholder="确认密码" v-model="confirmpwd.value" @change="checkPasswordForm" @keyup="checkPasswordForm"/>
                    <div class="input-group-append">
                        <b-button variant="outline-secondary" :pressed="confirmpwd.show"><b-icon :icon="confirmpwd.icon" @click="toggleConfirmpwdShow"/></b-button>
                    </div>
                </div>
                <small class="text-danger" v-if="errors.confirmpwd">{{errors.confirmpwd}}</small>
            </div>
        </form>
    </b-modal>
    <!-- modal end -->
</template>
<script>
export default {
    name: 'PasswordDialog',
    props:{
        visible:{
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            errors:{},
            password:{
                show: false,
                type: 'password',
                icon: 'eye',
                value: '',
            },
            confirmpwd: {
                show: false,
                type: 'password',
                icon: 'eye',
                value: '',
            },
        }
    },
    methods:{
        checkPasswordForm(){
            let password = this.password.value.trim(),
                confirmpwd = this.confirmpwd.value.trim(),
                errors = this.errors,
                hasError = false;
            if(password == '' ){
                errors.password = '密码不能为空'
                hasError = true
            }else if(password.length < 4){
                errors.password = '密码长度不能小于4位'
                hasError = true
            }else{
                errors.password = null
            }
            if(confirmpwd != password){
                errors.confirmpwd = '输入密码不一致'
                hasError = true
            }else{
                errors.confirmpwd = null;
            }
            this.$forceUpdate();
            return {password,hasError}
        },
        changeShow(e){
            this.$emit('close',e)
        },
        onChangePassword(){
            let {password,hasError}  = this.checkPasswordForm()
            if(hasError) return false;
            this.$emit('changepassword', password);
        },
        togglePasswordShow(){
            let show = !this.password.show
            this.password.type = show ? 'text' : 'password'
            this.password.icon = show ? 'eye-slash' : 'eye'
            this.password.show = show
        },
        toggleConfirmpwdShow(){
            let show = !this.confirmpwd.show
            this.confirmpwd.type = show ? 'text' : 'password'
            this.confirmpwd.icon = show ? 'eye-slash' : 'eye'
            this.confirmpwd.show = show
        }
    }
}
</script>
<style>
</style>