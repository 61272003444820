<template>
<!-- .body start -->
<div class="d-flex flex-column body">
    <BreadCrumb :items="routes"/>
    <!-- .card start -->
    <div class="card mx-5">
        <div class="card-header">{{title}}</div>
        <!-- .card-body start -->
        <div class="card-body">
            <div class="row disp-row">
                <div class="col-2 text-right"><label>ID</label></div>
                <div class="col-8 value">{{oauthAccount.id}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>平台</label></div>
                <div class="col-8 value d-flex align-items-center">{{oauthAccount.platform_name}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>用户头像</label></div>
                <div class="col-8 value d-flex align-items-center"><ImagePreview :src="oauthAccount.avatar_url" width="100px" height="100px"/></div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>用户昵称</label></div>
                <div class="col-8 value d-flex align-items-center">{{oauthAccount.nickname}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>用户电话</label></div>
                <div class="col-8 value d-flex align-items-center">{{oauthAccount.mobile}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>性别</label></div>
                <div class="col-8 value d-flex align-items-center">{{gender}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>用户所在地</label></div>
                <div class="col-8 value d-flex align-items-center">{{loc}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>创建时间</label></div>
                <div class="col-8 value">{{created_at}}</div>
            </div>
            <div class="row disp-row">
                <div class="col-2 text-right"><label>更新时间</label></div>
                <div class="col-8 value">{{updated_at}}</div>
            </div>
        </div>
        <!-- .card-body end -->
        <!-- .card-footer start -->
        <div class="card-footer text-center">
            <router-link to="/crm/oauth_accounts" class="btn btn-outline-secondary">返回</router-link>
        </div>
        <!-- .card-foorter end -->
    </div>
    <!-- .card end -->
</div>
</template>
<script type="text/javascript">
import BreadCrumb from '@/components/breadcrumb.vue'
import ImagePreview from '@/components/imagepreview.vue'
import {dtformat} from '@/utils/helpers.js'
import {mapActions, mapGetters} from 'vuex'
export default{
    name: 'OauthAccountView',
    components:{
        BreadCrumb,ImagePreview
    },
    computed:{
        ...mapGetters(['oauthAccount']),
        created_at(){
            return dtformat(this.oauthAccount.created_at)
        },
        updated_at(){
            return dtformat(this.oauthAccount.updated_at)
        },
        loc(){
            let {country, province,city} = this.oauthAccount,
                loc = [country,province,city].filter(item => item && item.trim() != '');
            return loc.join(' ')
        },
        gender(){
            return this.oauthAccount.gender == 1 ? '男' : '女'
        }
    },
    data(){
        return {
            title: '外站账号详情',
            routes: ['crm_oauth_accounts','crm_oauth_account'],
        }
    },
    created(){
        this.getOauthAccount(this.$route.params.id)
    },
    methods:{
        ...mapActions(['getOauthAccount','destroyOauthAccount']),
    }
}
</script>

<style>
</style>
