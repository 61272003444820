<template>
    <nav aria-label="breadcrumb" v-if="items.length > 0 ">
      <ol class="breadcrumb">
        <li :class="`breadcrumb-item ${index == computedItems.length - 1 ? 'active' : ''}`" v-for="(item,index) in computedItems" v-bind:key="index">
            <router-link :to="{name: item.name, params: item.params}" v-if="index < computedItems.length - 1">{{item.title}}</router-link>
            <span v-else>{{item.title}}</span>
        </li>
      </ol>
    </nav>
</template>

<script>
import {findRoute} from '@/router.js'
export default {
    name: 'BreadCrumb',
    props:{
        items:{
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed:{
        computedItems(){
            let computedItems = []
            this.items.forEach(item => {
                let name =  item.name ? item.name : item,
                    route = findRoute(name)
                computedItems.push({title: route.route.name, name: route.route.alias, params: item.params || {}})
            })
            return computedItems
        }
    }
}
</script>