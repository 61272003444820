import image_templates from './powerimgs/image_templates'
import power_images from './powerimgs/power_images'

import index from './index'

import customers from './crm/customers'
import brands from './crm/brands'
import companies from './crm/companies'
import stores from './crm/stores'
import oauth_accounts from './crm/oauth_accounts'

import tags from './contents/tags'
import industries from './contents/industries'
import products from './contents/products'

import message_templates from './messages/templates'
import sys_messages from './messages/sys_messages'
import user_messages from './messages/user_messages'

import resources from './resources/resources'
import materials from './resources/materials'

import voices from './audios/voices'
import groups from './audios/groups'
import providers from './audios/providers'

import video_artifacts from './videos/video_artifacts'
import storyboards from './videos/storyboards'
import video_scenes from './videos/video_scenes'

import staffs from './system/staffs'
import users from './system/users'
import tasks from './tasks'

import direct from './direct_upload'

export default {
    powerimgs:{
        image_templates, power_images
    },
    contents:{
        products, tags, industries
    },
    crm:{
        customers, brands, companies, oauth_accounts, stores
    },
    system:{
        staffs, users
    },
    messages:{
        templates: message_templates,
        sys_messages,
        user_messages
    },
    videos:{
        video_artifacts,
        video_scenes,
        storyboards,
    },
    resources:{
        resources,
        materials,
    },
    audios:{
        voices,
        groups,
        providers,
    },
    ...index,
    tasks,
    direct
}