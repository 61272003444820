import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/resources/resources'
export default {
    ...mapApis(context),
    getFolder(id){
        return new Promise((resolve, reject) => {
            ajax.get(
                env.url(`/resources/folders/${id}.json`)
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    destroyResources(ids){
        return new Promise((resolve, reject) => {
            ajax.delete(
                env.url('/resources/resources/delete'),
                {params: {ids}}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    tagFolder(id, tags){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`/resources/folders/${id}/tag/`),
                {tags}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
    transformResource(id,transform){
        return new Promise((resolve, reject) => {
            ajax.post(
                env.url(`${context}/${id}/transform/`),
                {transform}
            ).then(function(res){
                resolve(res)
            }).catch(reject)
        })
    },
}