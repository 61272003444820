import video_artifacts from './video_artifacts'
import video_scenes from './video_scenes'
import storyboards from './storyboards'

export default {
    name: 'videos',
    modules: {
        video_scenes,
        video_artifacts,
        storyboards
    },
}