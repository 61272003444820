import templates from './templates'
import sys_messages from './sys_messages'
import user_messages from './user_messages'


export default {
    name: 'messages',
    modules: {
        templates,
        sys_messages,
        user_messages
    }
}