import requests from '@/requests/requests.js'
import {mapBaseStates,mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.system.staffs,
      name = 'staff'
export default {
    name: 'staffs',
    state: {
        ...mapBaseStates(null,{account:{}})
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        changeStaffPassword: async(context,{id,password}) => api.changePassword(id,password) ,
        changeMyPassword: async(context,{password}) => api.changeMyPassword(password) ,
        checkStaffMobile: async(context,{mobile,id}) =>  api.checkMobile(mobile,id),
        checkStaffEmail: async(context,{email,id}) => api.checkEmail(email,id),
    }
}