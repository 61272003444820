import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.videos.video_scenes,
      name = 'video_scene',
      alignTypes = {start:'左对齐', middle: '居中', right: '右对齐'},
      fontValignTypes = {"text-before-edge": "顶部对齐", "central":"居中","text-after-edge":"底部对齐"},
      valignTypes = {top:'顶部对齐', middle: '居中', bottom: '底部对齐'};
export default {
    name: 'video_scenes',
    state: {
        ...mapBaseStates({type:null}),
    },
    getters:{
        ...mapBaseGetters(name),
        alignTypes: () => alignTypes,
        fontValignTypes: () => fontValignTypes,
        valignTypes: () => valignTypes,
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}