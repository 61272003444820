<template>
    <div class="row mr-1">
        <div class="col-sm-12 col-md-6 col-x-6 pt-2">
            共 {{value.count}} 条记录
            <span v-if="value.results && value.results.length > 1">,当前显示第 {{(value.page -1) * value.page_size + 1}} - {{(value.page-1) * value.page_size + (value.results ? value.results.length : 0)}} 条记录</span>
            <span v-else-if="value.results && value.results.length == 1">,当前显示第 {{(value.page-1) * value.page_size + 1}} 条记录</span>
        </div>
        <div class="col-sm-12 col-md-6 col-x-6">
            <b-pagination :value="value.page" :total-rows="value.count" :per-page="value.page_size" @input="onChange" align="right"/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageSummary',
    props:{
        value:{
            type: [Object,Array],
            required: true,
            default: () => {}
        }
    },
    methods:{
        onChange(val){
            this.$emit('input',val)
            this.$emit('change',val)
        }
    }

}
</script>
<style>
</style>