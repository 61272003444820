import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
export const CUSTOMER_STATUS_MAP = {null: '全部', 0: '新建', 1: '待审核', 2: '已退回', 3: '已生效', 9 : '已禁用'}
export const CUSTOMER_STATUS_ORDER = [null,0,1,2,3,9]

const api = requests.crm.customers,
      name = 'customer'
export default {
    name: 'customers',
    state: {
        ...mapBaseStates(
            /** search */
            {
                search: '',
                brand: null,
                status: null
            }),
        statusStats: {}
    },
    getters:{
        ...mapBaseGetters(name),
        customerStatusMap: () => CUSTOMER_STATUS_MAP,
        customerStatusOrder: () => CUSTOMER_STATUS_ORDER,
        customerStatusStats: (state) => state.statusStats ,
    },
    mutations:{
        ...mapBaseMutations(name,(data) => {
            data.status_name = CUSTOMER_STATUS_MAP[data.status]
            return data
        }),
        setCustomerStatusStats: (state,data) => state.statusStats = data
    },
    actions: {
        ...mapBaseActions(name,api),
        checkCustomerMobile: async(context,{mobile,id}) => api.checkMobile(mobile,id),
        checkCustomerEmail: async(context,{email,id}) => api.checkEmail(email,id),
        getCustomerStatusStats: async({commit},params) => {
            let data = await api.getCustomerStatusStats(params)
            commit('setCustomerStatusStats', data)
            return data
        }
    }
}