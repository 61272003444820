import { DateTime } from "luxon";
import {env} from '@/configs.js'
import swal from 'sweetalert2';

const _specialSuffixes = {
    'y' : 'ies',
    'f' : 'ves',
    's' : 'ses',
    'x' : 'xes',
    'staff' : 'staffs'
}

export function px(val, ctx){
    try{
        if(val.endsWith('%')){
            ctx = isNaN(ctx) ? parseInt(ctx.replace('px','')) : ctx;
            val = parseInt((parseFloat(val.replace('%','')) /100) * ctx);
        }else{
            val = parseInt(val.replace('px',''))
        }
    }catch(e){
        val = null;
    }
    return val;
}

export function px2percent(val,ctx){
    return parseInt(val / ctx * 100);
}

export function percent2px(val,ctx){
    return parseInt((val / 100) * ctx);
}

export const commentAction = function(id, title, handler,opts){
    opts = opts || {placeholder: '工作记录备注', input: 'textarea'}
    return new Promise((resolve, reject) => {
        swal.fire({
            title: title,
            inputLabel: opts.placeholder,
            input: opts.input,
            showCancelButton: true,
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            showLoaderOnConfirm: true,
            preConfirm: (comment) => {
                return handler({id,comment}).then(resp => {
                    console.log('提交成功:', resp)
                    return {hasError: false, message: '提交成功'}
                }).catch(ex =>{
                    console.error('提交失败',ex)
                    return {hasError: true, message: '提交失败'}
                })
            }
        }).then(resp => {
            if(!resp.isConfirmed) return;
            let val = resp.value || {}
            swal.fire({
                title: val.hasError ? val.message : '请求成功',
                icon: val.hasError ? 'error' : 'success',
                showCancelButton: false,
                confirmButtonText: '确认'
            }).then(resolve)
        }).catch(reject)
    });
}

export const dtformat = (val) => {
    if(!val) return ''
    let dt = DateTime.fromISO(val,{setZone: true});
    return dt.toLocaleString(DateTime.DATETIME_SHORT);
}

export const isActive = (val) => {
    return val ? '可用' : '不可用'
}

function snake2camel(str){
    let camel = str.split('_').map(part => part[0].toUpperCase() + part.substring(1,part.length)).join('')
    return camel[0].toLowerCase() + camel.substring(1,camel.length);
}

/** 转换名字的不同版本 */
export const transformNames = function(name){
    let single = name.toLowerCase(),
        camel = snake2camel(single),
        last = single[single.length-1],
        plural = single +'s',
        camelPlural = camel + 's',
        parts = single.split('_'),
        lastWord = parts.pop();
    if(lastWord in _specialSuffixes) {
        parts.push(_specialSuffixes[single]);
        plural = parts.join('_')
        camelPlural = snake2camel(plural)
    }
    else if(last in _specialSuffixes) {
        plural = single.substring(0,single.length-1) + _specialSuffixes[last]
        camelPlural = snake2camel(plural)
    }

    return {
        plural, single,camel, camelPlural,
        capital: camel[0].toUpperCase() + camel.substring(1,camel.length),
        capitalPlural: camelPlural[0].toUpperCase() + camelPlural.substring(1,camelPlural.length),
    }
}

export const dateformat = (val) => {
    if(!val) return ''
    return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
}

export const getCookie = (name,defaultVal) => {
    var cookieValue = defaultVal;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const hasLogin = () => {
    return !!localStorage.getItem('__accessToken');
}

export const printValue = (context,val,defaultVal) => {
    if(!defaultVal)  defaultVal = ''
    return (context && context[val]) || defaultVal
}

export const confirmAction = async (content) => {
    return new Promise((res) => {
        swal.fire({
            title: '确认操作',
            icon: 'question',
            text: content,
            showCancelButton: true,
            cancelButtonText: '取消',
            confirmButtonText: '确认'
        }).then(ctx => {
            if(ctx.isConfirmed) res()
        })
    })
}

export const showAlert = async (title,type) => {
    return new Promise((res) => {
        swal.fire({
            title: title || '操作成功',
            icon: type || 'success',
            showCancelButton: false,
            confirmButtonText: '确认'
        }).then(ctx => {
            if(ctx.isConfirmed) res()
        })
    })
}

export const mapSearchActions = (search) => {
    return {
        /**
         * 分页链接点击事件
         */
        paginate(page){
            let s = this[search]
            s.page = page
            this.doSearch(s)
        },
        /**
         * 新搜索的事件，会返回第一页
         */
        onSearch(){
            let s = this[search]
            s.page = null
            this.doSearch(s)
        },
        /**
         * 排序事件
         */
        onSort(ctx){
            let {sortBy,sortDesc} = ctx,
                s = this[search]
            s.ordering = `${sortDesc ? '-' : ''}${sortBy}`
            this.onSearch()
        },
    }
}

export const gender = (val) => {
    return val == 1 ? '男性' : (val == 0 ? '女性' : '未知')
}

/**
 * 按照 ASCII码的长度计算字符串的长度， 非ASCII码算2倍长度
 */
export const asciiLength = (str) => {
    // eslint-disable-next-line
    let ascii = str.replace(/[^\x00-\x7F]/g, "");
    return (str.length - ascii.length) * 2 + ascii.length;
}


/**
 * 把浮点数定位到精准位
 */
export const toFixed = (int, precise) => {
    let base = Math.pow(10,precise)
    return parseInt(base * int) / base
}

export const audioByPass = (url) => {
    url = url.replaceAll(/^https?:\/\//g,'')
    return env.url(`/audios/bypass/${url}`);
}

export const flatten = (array) =>{
    let results = []
    array.forEach(items => {
        if(items && typeof(items) == 'object' && items.length != undefined){
            results = results.concat(flatten(items))
        }else{
            results.push(items)
        }
    });
    return results;
}

/**
 * 唯一化数组元素
 */
export const distinct = (array, distinctField) => {
    let results = []
    array.forEach(item => {
        for(let i in results){
            if(distinctField){
                if(typeof(distinctField) == 'function' && distinctField(results[i]) == distinctField(item)){
                    return
                }else if(results[i][distinctField] == item[distinctField]){
                    return
                }
            }else if(results[i] == item) return
        }
        results.push(item)
    })
    return results;
}

/**
 * 动态加载网络字体
 */
export const loadFont = async (name, url) => {
    const font = new FontFace(name, `url(${url})`);
    await font.load();
    document.fonts.add(font)
}