import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.contents.products,
      name = 'product'
export default {
    name: 'products',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        getIndustriesProducts({commit}, {industries,search}){
            return new Promise((res,rej) => {
                api.getIndustriesProducts(industries.map(i => i.id),search).then(e =>{
                    commit('setProducts',e.data)
                    res(e)
                }).catch(ex => {
                    rej(ex)
                })
            })
        }
    }
}