import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
import {CUSTOMER_STATUS_MAP} from './customers'
const api = requests.crm.brands,
      name = 'brand'
export default {
    name: 'brands',
    state: {
        ...mapBaseStates(),
        statusStats: {},
    },
    getters:{
        ...mapBaseGetters(name),
        brandStatusStats: (state) => state.statusStats ,
    },
    mutations:{
        ...mapBaseMutations(name,(data) => {
            data.status_name = CUSTOMER_STATUS_MAP[data.status]
            return data
        }),
        setBrandStatusStats: (state,data) => state.statusStats = data
    },
    actions: {
        ...mapBaseActions(name,api),
        async getCustomerBrands({commit}, {id,page}){
            let resp = await api.getCustomerBrands(id,page)
            commit('setBrands', resp.data)
            return resp
        },
        getBrandStatusStats: async({commit},params) => {
            let data = await api.getBrandStatusStats(params)
            commit('setBrandStatusStats', data)
            return data
        },
        brandApplicationApproval: async({commit}, {action, id,form}) => {
            let data = await api.approval(action,id, form);
            commit('setBrand', data.data)
            return data
        }
    }
}