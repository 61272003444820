import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.crm.brands,
      name = 'company'
export default {
    name: 'companies',
    state: {
        ...mapBaseStates(),
        statusStats: {}
    },
    getters:{
        ...mapBaseGetters(name),
        companyStatusStats: (state) => state.statusStats ,
    },
    mutations:{
        ...mapBaseMutations(name),
        setCompanyStatusStats: (state,data) => state.statusStats = data
    },
    actions: {
        ...mapBaseActions(name,api),
        getCompanyStatusStats: async({commit},params) => {
            let data = await api.getCompanyStatusStats(params)
            commit('setCustomerStatusStats', data)
            return data
        }
    }
}