import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/crm/companies'
export default {
    ...mapApis(context),
    async getCompanyStatusStats(params){
        return ajax.get(
            env.url(context, `/status_stats/`),
            {params}
        )
    },
 }