import ajax from '@/requests/ajax'
import {env} from '@/configs'
import {mapApis} from '../base'

export default {
    ...mapApis('/system/staffs'),
    async changePassword(id,pwd){
       return ajax.post(
           env.url(`/system/staffs/${id}/password/`),
           {pwd}
       )
    },
    async changeMyPassword(pwd){
       return ajax.post(
           env.url(`/system/my/password`),
           {pwd}
       )
    },
    async checkMobile(mobile,id){
        return ajax.get(
            env.url(`/system/staffs/checkmobile/`),
            {params:{mobile,id}}
        )
    },
    async checkEmail(email,id){
        return ajax.get(
            env.url(`/system/staffs/checkemail/`),
            {params:{email,id}}
        )
    },
}