import requests from '@/requests/requests.js'
import {mapBaseStates,mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.messages.templates,
      name = 'message_template'
export default {
    name: 'message_templates',
    state: {
        ...mapBaseStates()
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}