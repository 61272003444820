import ajax from '@/requests/ajax'
import {env} from '@/configs'
import {mapApis} from '../base'

const context = '/crm/stores'
export default {
    ...mapApis(context),
    async getStatusStats(params){
        return ajax.get(
            env.url(context, `/status_stats/`),
            {params}
        )
    },
    async approval(action,id,form){
        return ajax.post(
            env.url(context, `/${id}/${action}/`),
            form
        )
    },
}