import requests from '@/requests/requests.js'
import {mapBaseStates,mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.system.users,
      name = 'user'
export default {
    name: 'users',
    state: {
        ...mapBaseStates()
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}