<template>
<div class="d-flex flex-column">
    <BreadCrumb :items="routes"/>
    <div class="p-3">
        <b-form inline @submit.prevent="onSearch">
            <div class="form-group mr-2">
                <label class="form-label mr-1" for="input-title">关键字:</label>
                <b-form-input
                  id="input-title"
                  class="form-control"
                  v-model="oauthAccountSearch.search"
                  placeholder="用户昵称"
                ></b-form-input>
             </div>
             <button type="submit" class="btn btn-primary btn-sm mr-3">搜索</button>
        </b-form>
    </div>
    <div class="p-3">
        <PageSummary :value="oauthAccounts" @change="paginate"/>
        <b-table hover striped :items="oauthAccounts.results" :busy="isBusy" :fields="fields" head-variant="dark" show-empty @sort-changed="onSort">
            <template #table-busy>
                <div class="text-center text-dark my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>加载中...</strong>
                </div>
            </template>
            <template #cell(account)="data">
                <div class="d-flex flex-row align-items-center">
                <ImagePreview :src="data.item.avatar_url" width="50px" height="50px"/>
                {{data.item.nickname}}
                </div>
            </template>
            <template #cell(action)="data">
                <router-link :to="`/crm/oauth_accounts/${data.item.id}/show`" class="btn btn-primary btn-sm mr-3">查看</router-link>
            </template>
            <template #empty="_">
                <div class="d-flex flex-column align-items-center py-3-"><small>无匹配记录</small></div>
            </template>
        </b-table>
    </div>
</div>
</template>

<script>
import BreadCrumb from '@/components/breadcrumb.vue'
import ImagePreview from '@/components/imagepreview.vue'
import PageSummary from '@/components/pagesummary.vue'
import {dtformat,mapSearchActions} from '@/utils/helpers.js'
import {mapActions, mapGetters} from 'vuex'
export default {
    name: 'OAuthAccountIndex',
    components:{
        BreadCrumb,ImagePreview,PageSummary
    },
    data(){
        return {
            title: '外站账号管理',
            // 面包屑路径
            routes: ['crm_oauth_accounts'],
            isBusy: false,
            fields:[
                {key: 'id',label: 'ID',sortable: true},
                {key: 'account', label: '账号'},
                {key: 'platform_name',sortable: true, label: '平台'},
                {key: 'created_at',label: '创建时间',sortable: true, formatter: dtformat},
                {key: 'action',label: '操作',sortable: false}
           ]
        }
    },
    computed:{
        ...mapGetters(['oauthAccounts','oauthAccountSearch']),
    },
    created(){
        //初始化
        this.doSearch()
    },
    methods:{
        ...mapActions(['getOauthAccounts','destroyOauthAccount']),
        ...mapSearchActions('oauthAccountSearch'),
        doSearch(search){
            this.getOauthAccounts({search})
        },
        onDestroy(e){
            let id = e.target.dataset.id
            this.destroyOauthAccount({id}).then(() => {
                this.doSearch();
            })
        }
    }
}
</script>

<style>
</style>
