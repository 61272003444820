<template>
    <b-navbar toggleable="lg" type="dark" variant="dark" sticky>
        <b-navbar-brand href="#">乾坤袋智能内容系统</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                 <b-nav-item-dropdown right>
                      <!-- Using 'button-content' slot -->
                      <template #button-content>
                        <em>{{currentUser}}</em>
                      </template>
                      <b-dropdown-item @click="showPassword=true">更改密码</b-dropdown-item>
                      <b-dropdown-item @click="logout">登出</b-dropdown-item>
                 </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
        <PasswordDialog :visible="showPassword" @changepassword="onChangePassword" @close="showPassword=false"/>
    </b-navbar>
</template>

<script>
import swal from 'sweetalert2'
import auth from '@/utils/auth'
import PasswordDialog from '@/components/password-dialog.vue'
import {mapActions, mapGetters} from 'vuex'
import {showAlert} from '@/utils/helpers'
export default {
    name: 'NavBar',
    components:{
        PasswordDialog
    },
    computed:{
        ...mapGetters(['staff']),
        currentUser(){
            let user = auth.currentUser();
            return user ? (user.fullname  ? user.fullname : user.username) : ''
        }
    },
    data(){
        return {
            showPassword:false,
        }
    },
    methods:{
        ...mapActions(['changeMyPassword']),
        onChangePassword(password){
            this.changeMyPassword({password}).then(() => {
                showAlert('更新成功').then(() => {
                    this.showPassword = false;
                })
            }).catch(ex => {
                let msg = typeof(ex) == 'string' ? ex : '系统错误'
                showAlert(msg,'error');
            })
        },

        /**
         * 登出
         */
        logout(){
            swal.fire({
                title: '您确定要登出系统吗？',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '确定',
            }).then(resp => {
                if(!resp.isConfirmed) return;
                auth.logout().then(()=>{
                    swal.fire({
                        title: '登出成功',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: '好的'
                    }).then(() => {
                        this.$router.replace({path: '/login'})
                    })
                })
            })
        }
    }
}
</script>

<style>
</style>