import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.contents.tags,
      name = 'tag',
      TAG_TYPES = {industry:'行业',product:'产品',content: '内容',loc:'地区',attr:'属性',platform: '平台',default:'通用',
        brand: '品牌', producer: '厂商', voice: '配音', content_catalog: '内容分类'}
export default {
    name: 'tags',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
        tagTypes:() => TAG_TYPES
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        triggerTagStatus(context, {id,status}){
            return api.triggerStatus(id,status)
        },
    }
}