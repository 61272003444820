import ajax from '@/requests/ajax'
import {env} from '@/configs'
import {mapApis} from '../base'

let context = '/contents/tags'
export default {
    ...mapApis(context),
    triggerStatus(id, status){
        return ajax.post(
           env.url(context, id,'status/'),
           {status}
        )
    },
}