import ajax from '@/requests/ajax'
import {env} from '@/configs.js'
import {mapApis} from '../base'

const context = '/audios/voices'
export default {
    ...mapApis(context),
    disable(voiceId){
        return ajax.post(env.url(`${context}/${voiceId}/disable.json`))
    },
    casting(voiceId, text){
        return ajax.post(env.url(`${context}/${voiceId}/casting.json`),{text})
    }
}