var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column body"},[_c('BreadCrumb',{attrs:{"items":_vm.routes}}),_c('div',{staticClass:"card mx-5"},[_c('div',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row disp-row"},[_vm._m(0),_c('div',{staticClass:"col-8 value"},[_vm._v(_vm._s(_vm.oauthAccount.id))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(1),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_vm._v(_vm._s(_vm.oauthAccount.platform_name))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(2),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_c('ImagePreview',{attrs:{"src":_vm.oauthAccount.avatar_url,"width":"100px","height":"100px"}})],1)]),_c('div',{staticClass:"row disp-row"},[_vm._m(3),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_vm._v(_vm._s(_vm.oauthAccount.nickname))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(4),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_vm._v(_vm._s(_vm.oauthAccount.mobile))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(5),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_vm._v(_vm._s(_vm.gender))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(6),_c('div',{staticClass:"col-8 value d-flex align-items-center"},[_vm._v(_vm._s(_vm.loc))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(7),_c('div',{staticClass:"col-8 value"},[_vm._v(_vm._s(_vm.created_at))])]),_c('div',{staticClass:"row disp-row"},[_vm._m(8),_c('div',{staticClass:"col-8 value"},[_vm._v(_vm._s(_vm.updated_at))])])]),_c('div',{staticClass:"card-footer text-center"},[_c('router-link',{staticClass:"btn btn-outline-secondary",attrs:{"to":"/crm/oauth_accounts"}},[_vm._v("返回")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("用户头像")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("用户昵称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("用户电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("性别")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("用户所在地")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("创建时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2 text-right"},[_c('label',[_vm._v("更新时间")])])
}]

export { render, staticRenderFns }