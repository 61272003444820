<template>
    <div class="d-flex flex-column">
        <BreadCrumb :items="routes"/>
        <div class="p-3">
            <b-form inline>
                <div class="form-group mr-2">
                    <label class="form-label mr-1" for="input-title">标题:</label>
                    <b-form-input
                      id="input-title"
                      class="form-control-sm"
                      v-model="search.title"
                      placeholder="记录标题关键字"
                    ></b-form-input>
                 </div>
                 <button type="button" class="btn btn-primary btn-sm">搜索</button>
            </b-form>
        </div>
        <div class="p-3">
            <b-table hover striped :items="items" :busy="isBusy" :fields="fields" head-variant="dark">
                <template #table-busy>
                    <div class="text-center text-dark my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>加载中...</strong>
                    </div>
                </template>
                <template #cell(action)="">
                    <a href="#">编辑</a>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/breadcrumb.vue'
export default {
    name: 'DashboardIndex',
    components:{
        BreadCrumb
    },
    data(){
        return {
            routes: ['root'],
            search:{
                title: ''
            },
            isBusy: false,
            items:[{title: 'test', _id: '1'}],
            fields:[{
                key: '_id',
                label: 'ID',
                sortable: true
            },{
                key: 'title',
                label: '标题'
            },{
                key: 'action',
                label: '操作',
                sortable: false
            }]
        }
    }
}
</script>

<style>
</style>
