import swal from 'sweetalert2'
import {transformNames} from '@/utils/helpers'

export const mapBaseStates =(search,item,items) => {
    return {
        items: items ? items : {count:0, page:1, pages:1, page_size:20,results:[]},
        item: item ? item : {},
        search: search ? search : {search: ''}
    }
}

export const mapBaseGetters = (name) => {
    let {camelPlural,camel} = transformNames(name),
        getters = {}
    getters[camel] =  state => state.item
    getters[camelPlural] =  state => state.items
    getters[camel+'Search'] = state => state.search
    return getters
}

export const mapBaseMutations = (name, formatter) => {
    let {capital,capitalPlural} = transformNames(name),
        mutations = {}
    mutations[`set${capitalPlural}`] =  (state,data) => {
        state.items = data
        if(formatter){
            if(state.items && state.items.results){
                state.items.results.forEach(item => formatter.call(null, item));
            }
        }
    }
    mutations[`set${capital}`] =  (state,data) => {
        state.item = data
        if(state.item && formatter) state.item = formatter.call(null,state.item)
    }
    mutations[`set${capital}Search`] =  (state,data) => {
        state.search = data
    }
    return mutations
}

export const mapBaseActions = (name, api) => {
    let {capital, capitalPlural} = transformNames(name),
        actions = {}
    actions[`get${capitalPlural}`] =  ({commit},data) => {
        let {search,setter} = data || {}
        return new Promise((res,rej) =>{
            api.list(search).then(resp => {
                //如果setter 为false则不设置
                if(setter !== false){
                    commit(setter || `set${capitalPlural}`,resp.data)
                }
                res(resp)
            }).catch(ex => {
                rej(ex)
            })
        })
    }
    actions[`clear${capitalPlural}`] = ({commit}) => {
        commit(`set${capitalPlural}`, {results:[]})
    }
    actions[`get${capital}`] = async ({commit},id) => {
        return new Promise((res, rej) =>{
            api.get(id).then(resp =>{
                commit(`set${capital}`, resp.data)
                res(resp)
            }).catch(ex => {
                rej(ex)
            })
        })
    }
    actions[`create${capital}`] = (context,data) => {
        let {form,noalert} = data || {}
        return new Promise((res, rej) => {
            api.create(form).then(resp => {
                console.log('创建成功!', resp)
                if(noalert){
                    console.log('创建记录成功',resp)
                    res(resp)
                }else{
                    swal.fire('创建成功!','已创建记录','success')
                        .then(() => res(resp))
                }
            }).catch(e => {
                console.error('创建失败!', e)
                if(noalert){
                    console.error('创建记录失败',e)
                    rej(e)
                }else{
                    swal.fire('创建失败!', '请联系系统管理员', 'error')
                        .then(()=> rej(e))
                }
            })
        })
    }
    actions[`destroy${capital}`] = (context, data) => {
        let {id,noalert} = data || {}
        return new Promise((res, rej) => {
            swal.fire({
                title: '操作确认',
                icon: 'question',
                text: `确定要删除此记录(ID: ${id})吗？`,
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '确认',
            }).then(resp => {
                if(resp.isConfirmed){
                    api.destroy(id).then(resp => {
                        console.log('删除记录：',resp)
                        if(noalert)
                            res(resp)
                        else
                            swal.fire('删除成功!', '记录已删除，关闭窗口刷新页面', 'success').then(()=> res(resp))
                    }).catch(e => {
                        console.error('删除记录失败',e)
                        if(noalert)
                            rej(e)
                        else
                            swal.fire('删除失败!', '记录删除出现错误，请于系统管理员联系', 'error').then(() => rej(e))
                    })
                }
            })
        })
    }
    actions[`update${capital}`] = (context,data) => {
        let {form, id,noalert} = data || {};
        return new Promise((res, rej) => {
            api.update(id, form).then(resp => {
                console.log('更新成功!', resp)
                if(noalert)
                    res(resp)
                else
                    swal.fire('更新成功!', '已更新记录', 'success')
                        .then(()=> res(resp))
            }).catch(e => {
                console.error('更新失败!', e)
                if(noalert)
                    rej(e)
                else
                    swal.fire('更新失败!', '请联系系统管理员', 'error')
                        .then(() =>rej(e))
            })
        })
    }
    return actions
}