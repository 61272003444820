// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-instant/dist/vue-instant.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-context/dist/css/vue-context.css';
import store from './store/index.js'
import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'

// 默认timezone
import { Settings } from "luxon";

// Configure the time zone
Settings.defaultZoneName = "UTC+8";

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.component('multi-select', Multiselect)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

