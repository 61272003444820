import ajax from '@/requests/ajax'
import {env} from '@/configs'
import {mapApis} from '../base'

const context = '/powerimgs/power_images'
export default {
    ...mapApis(context),
    async statusStats(params){
        return ajax.get(
            env.url(context, 'status_stats/'),
            {params}
        )
    },
    async triggerStatus(id,status,comment){
       return ajax.put(
            env.url(context, id, 'status/'),
            {comment,status}
       )
    },
    async assign(id,assignee_id){
        return ajax.post(
            env.url(context,id,'assign/'),
            {assignee_id}
        )
    }
}