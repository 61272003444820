<template>
    <div class="p-0 h-100 bg-custom">
        <b-nav class="sidebar flex-column justify-content-start h-100" pills small>
            <b-nav-item no-prefetch :active="isActive('/')" class="menu" href="#/"><b-icon icon="house-door-fill" :variant="theme('/')" class="mr-2" font-scale="1"></b-icon>首页</b-nav-item>
            <b-nav-item no-prefetch :active="isActive('/contents')" class="menu" v-b-toggle="'collapse-contents'"><b-icon icon="tags-fill" :variant="theme('/contents')" class="mr-2" font-scale="1"></b-icon>内容管理</b-nav-item>
            <b-collapse id="collapse-contents" class="submenu-panel bg-dark" :visible="isActive('/contents')">
                <b-nav-item no-prefetch :active="isActive('/tags')" class="submenu" href="#/contents/tags"><b-icon icon="tag-fill" class="mr-2" ></b-icon>标签管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/industries')" class="submenu" href="#/contents/industries"><b-icon icon="globe" class="mr-2" ></b-icon>行业管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/products')" class="submenu" href="#/contents/products"><b-icon icon="box" class="mr-2" ></b-icon>产品管理</b-nav-item>
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/crm')" class="menu" v-b-toggle="'collapse-crm'"><b-icon icon="people-fill" :variant="theme('/crm')" class="mr-2" font-scale="1"></b-icon>客户管理</b-nav-item>
            <b-collapse id="collapse-crm" class="submenu-panel bg-dark" :visible="isActive('/crm')">
                <b-nav-item no-prefetch :active="isActive('/customers')" class="submenu" href="#/crm/customers"><b-icon icon="person-fill" class="mr-2" ></b-icon>客户管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/stores')" class="submenu" href="#/crm/stores"><b-icon icon="house-fill" class="mr-2" ></b-icon>门店管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/oauth_accounts')" class="submenu" href="#/crm/oauth_accounts"><b-icon icon="globe2" class="mr-2" ></b-icon>外站账户管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/brands')" class="submenu" href="#/crm/brands"><b-icon icon="bookmarks-fill" class="mr-2" ></b-icon>品牌管理</b-nav-item>
                <!--b-nav-item no-prefetch :active="isActive('/companies')" class="submenu" href="#/crm/companies"><b-icon icon="building" class="mr-2" ></b-icon>企业管理</b-nav-item-->
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/powerimgs')" class="menu" v-b-toggle="'collapse-powerimgs'"><b-icon icon="image-fill" :variant="theme('/powerimgs')" class="mr-2" font-scale="1"></b-icon>能量图管理</b-nav-item>
            <b-collapse id="collapse-powerimgs" class="submenu-panel bg-dark" :visible="isActive('/powerimgs')">
                <b-nav-item no-prefetch :active="isActive('/image_templates')" class="submenu" href="#/powerimgs/image_templates"><b-icon icon="columns" class="mr-2" ></b-icon>模板管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/power_images')" class="submenu" href="#/powerimgs/power_images"><b-icon icon="images" class="mr-2" ></b-icon>能量图管理</b-nav-item>
            </b-collapse>
            <!--b-nav-item no-prefetch :active="isActive('/colorfilms')" class="menu" href="/colorfilms"><b-icon icon="palette-fill" :variant="theme('/colorfilms')" class="mr-2" font-scale="1"></b-icon>改色膜视频管理</b-nav-item>
            <b-nav-item no-prefetch :active="isActive('/ppfs')" class="menu" href="#/ppfs"><b-icon icon="shield-fill" :variant="theme('/ppfs')" class="mr-2" font-scale="1"></b-icon>车衣视频管理</b-nav-item-->

            <b-nav-item no-prefetch :active="isActive('/messages')" class="menu" v-b-toggle="'collapse-message'">
                <b-icon icon="chat-fill" :variant="theme('/message')" class="mr-2" font-scale="1"></b-icon>消息管理
            </b-nav-item>
            <b-collapse id="collapse-message" class="submenu-panel bg-dark" :visible="isActive('/messages')">
                <b-nav-item no-prefetch :active="isActive('/messages/templates')" class="submenu" href="#/messages/templates"><b-icon icon="layers-fill" class="mr-2" ></b-icon>消息模版管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/sys_messages')" class="submenu" href="#/messages/sys_messages"><b-icon icon="envelope-fill" class="mr-2" ></b-icon>系统消息管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/user_messages')" class="submenu" href="#/messages/user_messages"><b-icon icon="chat-right-dots-fill" class="mr-2" ></b-icon>用户消息管理</b-nav-item>
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/audios')" class="menu" v-b-toggle="'collapse-audios'"><b-icon icon="folder-fill" :variant="theme('/audios')" class="mr-2" font-scale="1"></b-icon>音频管理</b-nav-item>
            <b-collapse id="collapse-audios" class="submenu-panel bg-dark" :visible="isActive('/audios')">
                <b-nav-item no-prefetch :active="isActive('/audios/groups')" class="submenu" href="#/audios/groups"><b-icon icon="people-fill" class="mr-2" ></b-icon>配音组管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/audios/voices')" class="submenu" href="#/audios/voices"><b-icon icon="mic-fill" class="mr-2" ></b-icon>配音管理</b-nav-item>
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/resources')" class="menu" v-b-toggle="'collapse-resources'"><b-icon icon="folder-fill" :variant="theme('/resources')" class="mr-2" font-scale="1"></b-icon>资源管理</b-nav-item>
            <b-collapse id="collapse-resources" class="submenu-panel bg-dark" :visible="isActive('/resources')">
                <b-nav-item no-prefetch :active="isActive('/resources/resources')" class="submenu" href="#/resources/resources"><b-icon icon="collection-play-fill" class="mr-2" ></b-icon>资源管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/resources/materials')" class="submenu" href="#/resources/materials"><b-icon icon="camera-video-fill" class="mr-2" ></b-icon>门店素材管理</b-nav-item>
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/videos')" class="menu" v-b-toggle="'collapse-videos'"><b-icon icon="camera-reels-fill" :variant="theme('/videos')" class="mr-2" font-scale="1"></b-icon>视频管理</b-nav-item>
            <b-collapse id="collapse-videos" class="submenu-panel bg-dark" :visible="isActive('/videos')">
                <b-nav-item no-prefetch :active="isActive('/videos/storyboards')" class="submenu" href="#/videos/storyboards"><b-icon icon="square" class="mr-2" ></b-icon>视频模版管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/videos/scenes')" class="submenu" href="#/videos/scenes"><b-icon icon="image-fill" class="mr-2" ></b-icon>片段模版管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/videos/artifacts')" class="submenu" href="#/videos/artifacts"><b-icon icon="youtube" class="mr-2" ></b-icon>视频成品管理</b-nav-item>
            </b-collapse>
            <b-nav-item no-prefetch :active="isActive('/system')" class="menu" v-b-toggle="'collapse-system'"><b-icon icon="gear-fill" :variant="theme('/system')" class="mr-2" font-scale="1"></b-icon>系统管理</b-nav-item>
            <b-collapse id="collapse-system" class="submenu-panel bg-dark" :visible="isActive('/system')">
                <b-nav-item no-prefetch :active="isActive('/system/staffs')" class="submenu" href="#/system/staffs"><b-icon icon="file-earmark-person" class="mr-2" ></b-icon>员工管理</b-nav-item>
                <b-nav-item no-prefetch :active="isActive('/system/tasks')" class="submenu" href="#/system/tasks"><b-icon icon="file-earmark-person" class="mr-2" ></b-icon>任务管理</b-nav-item>
            </b-collapse>
        </b-nav>
    </div>
</template>

<script>
export default {
    name: 'SideBar',
    props: ['current'],
    methods:{
        isActive(){
            if(arguments.length > 1){
                for(let i in arguments){
                    let path = arguments[i]
                    if(this.current.indexOf(path) >= 0) return true
                }
                return false
            }
            let path = arguments[0]
            if(path == '/') return this.current == path
            return this.current.indexOf(path) >= 0
        },
        theme(){
            return this.isActive.apply(this,arguments) ? 'light' : 'dark'
        }
    }
}
</script>

<style>
.sidebar{
    border-right: 1px solid #eee;
    min-width: 150px;
    .nav-link{
        border-radius: 0px !important;
        text-align:left;
        color: white;
        text-decorate: none;
    }
    .menu{
        .nav-link{
            padding: 15px 10px;
            color: black;
            &.active{
                color: white !important;
            }
        }
    }
    .submenu{
        background: #343a40;
        .nav-link{
            color: white;
            padding: 10px 0px;
            padding-left: 30px;
            &.active{
                background: #e33897;
                color: white;
            }
        }
    }
}
.bg-custom{
    background: rgba(1,1,1,0.1);
}
.submenu-panel{
}
</style>