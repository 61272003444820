import requests from '@/requests/requests.js'
import {mapBaseStates,mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
const STATUS_MAP = {null: '全部', 0: '待审', 1: '已审核', 2: '已拒审',10: '待发布', 11: '已发布',20: '已撤回'}
const WITHDRAW_MAP = {null: '全部', 0: '未撤回', 1: '撤回中', 2: '已撤回'}
const MSG_TYPE_MAP = {null: '全部', assist: '系统小助手', system: '系统通知'}

const api = requests.messages.sys_messages,
      name = 'sys_message'
export default {
    name: 'sys_messages',
    state: {
        ...mapBaseStates()
    },
    getters:{
        ...mapBaseGetters(name),
        sysMessageStatusMap: () => STATUS_MAP,
        sysMessageWithdrawMap: () => WITHDRAW_MAP,
        sysMessageMsgTypeMap: () => MSG_TYPE_MAP,
    },
    mutations:{
        ...mapBaseMutations(name, data => {
            data.status_name = STATUS_MAP[data.status]
            if(data.withdraw > 0){
                data.status_name = WITHDRAW_MAP[data.withdraw]
            }
            data.msg_type_name = MSG_TYPE_MAP[data.msg_type]
            return data
        }),
    },
    actions: {
        ...mapBaseActions(name,api),
        approveSysMessage(context, {id, comment}){
            return api.approve(id,comment)
        },
        rejectSysMessage(context, {id, comment}){
            return api.reject(id,comment)
        },
        reApproveSysMessage(context, id){
            return api.reApprove(id)
        },
        publishSysMessage(context, id){
            return api.publish(id)
        },
        withdrawSysMessage(context,{id,comment}){
            return api.withdraw(id,comment)
        },
        cancelWithdrawSysMessage(context, id){
            return api.cancelWithdraw(id)
        },
    }
}