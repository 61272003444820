import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'

const api = requests.contents.industries,
      name = 'industry'
export default {
    name: 'industries',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        addIndustryProduct(context,{id,product}){
            return api.addProduct(id,product)
        }
    }
}