import VueRouter from "vue-router";
import appRoutes from './pages/routes.js'
import {hasLogon} from '@/requests/login'

let pathNames = {}

/**
 * 注册路径和所要打开的页面
 */
function register(route,scopes){
    let path = scopes.join('/').replaceAll('//','/'),
        pathName = route.alias;
    if(pathName === '') pathName = 'root'
//    console.log('register path:', path, ', route: ', route, 'pathName:', pathName)
    // register path name
    pathNames[pathName] = {path, route}
    return {
        path,
        name:pathName,
        component: route.component,
        meta: route.meta,
    }
}

/**
 * 把作用域按顺序正确组装起来,用于后面组装成上下文路径
 */
function contexts(scopes){
    let newScopes = []
    for(let scope in scopes){
        let path = scopes[scope]
        if(!path || path == '') continue
        newScopes.push(path)
    }
    return newScopes
}

function lookup(subroutes,scopes,routes){
    if(!scopes) scopes = []
    if(!routes) routes = []
    if(!Array.isArray(subroutes)){
        if(subroutes.component){
            routes.push(register(subroutes, contexts([...scopes, subroutes.path])))
        }else{
            for(let path in subroutes){
                routes = lookup(subroutes[path],
                    contexts([...scopes, path]),
                    routes)
            }
        }
        return routes;
    }
    subroutes.forEach(route => {
        routes.push(register(route, contexts([...scopes, route.path])))
    })
    return routes
}

const routes = lookup(appRoutes)
const router = new VueRouter({routes,});

router.beforeEach((to,from,next) => {
//    console.log('[beforeEach] => to:',to,', from:',from)
    let route = pathNames[to.name];
    if(to.matched.some(record => record.meta.noauth)){
        router.app.$store.commit('setBlankPage',route ? route.route.blank : false)
        router.app.$store.commit('setCurrentPath',to.path)
        next()
    } else {
        hasLogon().then(() => {
            router.app.$store.commit('setBlankPage',route ? route.route.blank : false)
            router.app.$store.commit('setCurrentPath',to.path)
            next()
        }).catch(() =>{
            if(from.name != 'login' && to.name != 'login') next('/login')

        })
    }

})

export default router;
export const findRoute = (name) => pathNames[name]