import customers from './customers/routes.js'
import stores from './stores/routes.js'
//import companies from './companies/routes.js'
import brands from './brands/routes.js'
import oauth_accounts from './oauth_accounts/routes.js'

export default {
    '/customers': customers,
    '/brands': brands,
    '/stores': stores,
    /*'/companies': companies,*/
    '/oauth_accounts': oauth_accounts,
}