import ajax from '@/requests/ajax'
import {env} from '@/configs'
import {mapApis} from '../base'

export default {
    ...mapApis('/messages/sys'),
    approve(id,comment){
        return ajax.post(
            env.url(`/messages/sys/${id}/approve/`),
            { comment }
        )
    },
    reject(id,comment){
        return ajax.post(
            env.url(`/messages/sys/${id}/reject/`),
            { comment }
        )
    },
    reApprove(id){
        return ajax.post(
            env.url(`/messages/sys/${id}/reapprove/`)
        )
    },
    publish(id){
        return ajax.post(
            env.url(`/messages/sys/${id}/publish/`)
        )
    },
    withdraw(id,comment){
        return ajax.post(
            env.url(`/messages/sys/${id}/withdraw/`),
            { comment }
        )
    },
    cancelWithdraw(id){
        return ajax.post(
            env.url(`/messages/sys/${id}/cancel_withdraw/`)
        )
    },
}