import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'


const api = requests.videos.storyboards,
      name = 'storyboard'
export default {
    name: 'storyboards',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
    },
    mutations:{
        ...mapBaseMutations(name,item => {
            item.configs = item.configs || {}
            item.configs.scenes = item.configs.scenes || []
            return item;
        }),
    },
    actions: {
        ...mapBaseActions(name,api),
    }
}