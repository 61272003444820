import {env} from '@/configs'
import ajax from '@/requests/ajax'
import {mapApis} from '../base'

const context = '/contents/products'
export default {
    ...mapApis(context),
    getIndustriesProducts(ids,search){
        return ajax.get(
            env.url(context,'industries.json'),
            {params: {ids, search}}
        )
    }
}