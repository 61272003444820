/* eslint-disable */
const dev = {
   name: 'dev',
   host: 'http://localhost:8000',
   // 测试临时token
   token: 'f0c89a65f0950e485a3939c62548b0f916eb46a9'
}
const prod = Object.assign({...dev},{
    name: 'prod',
    host: 'https://api.youmohui.com.cn',
})

export const env = {
        current: prod,
        url(){
            let paths = []
            for(let arg in arguments) paths.push(arguments[arg])
            let path = paths.join('/').replaceAll('//','/')
            if(!path.startsWith('/')) path = '/' + path
            return this.current.host + path
        }
}