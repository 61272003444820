import {env} from '@/configs'
import ajax from '@/requests/ajax'
import {mapApis} from '../base'

const context = '/contents/industries'
export default {
    ...mapApis(context),
    addProduct(id,product){
        return ajax.post(
            env.url(context,id,'products/'),
            {product}
        )
    }
}