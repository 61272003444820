import axios from 'axios'
import ajax from './ajax';
import {env} from '@/configs.js'

export const login = async (username, password) => {
    return axios.post(
        env.url(`/system/login`),
        {username,password}
    )
}

export const hasLogon = async () => {
    return ajax.get(
        env.url('/system/has_logon')
    )
}