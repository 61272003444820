import ajax from './ajax'
import {env} from '@/configs.js'

/**
 * 生成最基础的CRUDAPI
 */
export const mapApis = function(context,excepts=null){
    let apis =  {
        async create(form){
            return ajax.post(
                env.url(`${context}.json`),
                form
            )
        },
        async update(id, form){
            return ajax.put(
                env.url(`${context}/${id}.json`),
                form
            )
        },
        async list(search){
            return ajax.get(
                env.url(`${context}.json`),
                {params: search}
            )
        },
        async get(id){
            return ajax.get(env.url(`${context}/${id}.json`))
        },
        async destroy(id){
            return ajax.delete(env.url(`${context}/${id}.json`))
        },
    }
    if(excepts && excepts.length){
        excepts.forEach(exp => {
            if(exp in apis)
                delete apis[exp]
        })
    }
    return apis
}