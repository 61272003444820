import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
const RESOURCE_TYPE_MAP = {'image': '图片', 'video': '視頻','audio': '音頻', 'file': '文件', 'font': '字体'}
const STATUS_MAP = {0: '不可用', 1: '可用'}

const api = requests.resources.resources,
      name = 'resource'
export default {
    name: 'resources',
    state: {
        ...mapBaseStates(),
        fonts: [],
    },
    getters:{
        ...mapBaseGetters(name),
        resourceTypeMap(){ return RESOURCE_TYPE_MAP},
        resourceStatusMap(){ return STATUS_MAP},
        fonts: (state) => { return state.fonts},
    },
    mutations:{
        ...mapBaseMutations(name,(data) => {
            data.status_name = STATUS_MAP[data.status]
            return data
        }),
        setFonts(state,fonts){
            state.fonts = fonts;
        },
    },
    actions: {
        ...mapBaseActions(name,api),
        destroyResources(context,ids){
            return api.destroyResources(ids)
        },
        getResourceType(context,type){
            let  mainType = type.split('/')[0]
            switch(mainType){
                case 'audio':
                    return 'audio';
                case 'video':
                    return 'video';
                case 'image':
                    return 'image';
                case 'font':
                    return 'font';
                default:
                    return 'file';
            }
        },
        async getFonts({commit}){
            let resp =  await api.list({type: 'font'})
            commit(`setFonts`,resp.data.results)
        },
        async transformResource(context,{id,transform}){
            return await api.transformResource(id,transform);
        },
    }
}