import powerImageRoutes from './powerimgs/routes.js'
import crmRoutes from './crm/routes.js'
import systemRoutes from './system/routes.js'
import videosRoutes from './videos/routes.js'
import messagesRoutes from './messages/routes.js'
import contentsRoutes from './contents/routes.js'
import resourcesRoutes from './resources/routes.js'
import audiosRoutes from './audios/routes.js'
import Dashboard from './dashboard/index'
import LoginPage from './login/index'
export default {
    '/': {component: Dashboard,name: '仪表盘',alias: 'root'},
    '/login': {component: LoginPage,name: '登录',alias: 'login',blank: true, meta:{ noauth: true }},
    '/contents': contentsRoutes,
    '/powerimgs': powerImageRoutes,
    '/resources': resourcesRoutes,
    '/videos': videosRoutes,
    '/audios': audiosRoutes,
    '/messages': messagesRoutes,
    '/crm': crmRoutes,
    '/system': systemRoutes,
}