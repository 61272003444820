import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
import {audioByPass} from '@/utils/helpers'
const VOICE_TYPES = ['童声','青年','成年','老年'];


const api = requests.audios.voices,
      providers = requests.audios.providers,
      name = 'voice';
let _interId = null;

export default {
    name: 'voices',
    state: {
        ...mapBaseStates({
            gender: null,
            limit : 12,
        }),
    },
    getters:{
        ...mapBaseGetters(name),
        voiceTypeMap: () => VOICE_TYPES,
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        getProviderVoices(context,{provider,form}){
           return providers.getProviderVoices(provider,form)
        },
        playDemo({dispatch},{provider,voice,callback}){
            console.log('playing demo...')
            providers.playDemo(provider,voice).then(res => {
                console.log('demo request resp => ', res.data)
                if(res.data.is_task){
                    console.log('Task response detected, will wait for ready')
                    dispatch('audioWaitForTask',{
                        taskId: res.data.link,
                        provider,
                        callback
                    });
                }else{
                    callback(audioByPass(res.data.link));
                }
            })
        },
        getVoiceTaskInfo(context, {provider,taskId}){
            return providers.getTaskInfo(provider,taskId);
        },
        pickVoice(context,{provider,voice}){
            return providers.pickVoice(provider,voice)
        },
        unpickVoice(context,{provider,voiceId}){
            return providers.unpickVoice(provider,voiceId)
        },
        disableVoice(context, voiceId){
            return api.disable(voiceId);
        },
        casting({dispatch}, {id,text,callback}){
            api.casting(id,text).then(res => {
                if(res.data.is_task){
                    console.log('Task response detected, will wait for ready')
                    dispatch('audioWaitForTask',{
                        provider:res.data.provider,
                        taskId: res.data.link,
                        callback
                    });
                }else{
                    callback(audioByPass(res.data.link));
                }
            })
        },
        audioWaitForTask({dispatch}, {provider,taskId,callback}){
            console.log('in audioWaitForTask => ', _interId, provider, taskId)
            if(_interId) clearInterval(_interId);
            let tries = 0;
            _interId = setInterval(() => {
                tries +1;
                dispatch('getVoiceTaskInfo',{provider,taskId}).then(res => {
                    console.log('audio task wait response => ', res.data)
                    if(res.data.link){
                        callback(audioByPass(res.data.link));
                        return dispatch('stopAudioWaitTask');
                    }else if(tries >= 20){
                        console.log('Exceeded max limit 20 times')
                        dispatch('stopAudioWaitTask');
                    }

                }).catch(ex => {
                    console.error('wait task with exception! ', ex);
                    dispatch('stopAudioWaitTask');
                })
            }, 3000);
        },
        stopAudioWaitTask(){
            console.log('stopping audio wait task!')
            if(!_interId) return;
            clearInterval(_interId)
            _interId = null;
        }
    }
}