import axios from 'axios'
import swal from 'sweetalert2'
import auth from '@/utils/auth'

function fireError(title,text){
  return swal.fire({
    title,text, icon: 'error',showCancelButton: false,confirmButtonText: "确定"
  })
}

function httpErrorHandler(error) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const response = error.response
    const request = error.request
    if (error.code === 'ERR_NETWORK') {
      console.error('网络连接异常...')
      fireError('系统异常', '请与系统管理员联系')
      throw response || error;
    } else if (error.code === 'ERR_CANCELED') {
      console.log('请求已取消...');
      throw response || error;
    } else if (error.code == 'ERR_BAD_RESPONSE'){
      fireError('请求失败', '未知错误')
      throw response || error;
    }
    if (response) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const statusCode = response.status
      if (statusCode === 401) {
        auth.clearUser()
        this.$router.replace('/login')
//        window.location.href= '/#/login'
        //redirect user to login
        return false;
      } else if (statusCode >= 400) {
        console.error('请求失败')
        fireError('请求失败',response.data && response.data.length < 20 ? response.data : null)
        throw response;
      }
    } else if (request) {
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
        console.log('无网络响应')
        return false;
    }
  } else if(error.code){
    fireError('请求错误','请于管理员联系')
    throw error;
  } else{
      //Something happened in setting up the request and triggered an Error
      console.error('网络异常:', error.message)
      //抛出异常
      fireError('未知错误', '请与管理员联系')
      throw error;
  }
  throw error
}

function responseHandler(response) {
  let config = response && response.config
  if (config.raw) {
    return response
  }
  if (response.status >= 200 && response.status < 300) {
    return response
  }else{
    throw response
  }
}

function responseErrorHandler(response) {
  const config = response && response.config
  if (config && config.raw) {
    return response
  }
  // the code of this function was written in above section.
  return httpErrorHandler(response)
}

const axiosDefaults = {}
const http = axios.create(axiosDefaults)
//register interceptor like this
http.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('__accessToken')
    if(token){
        config.headers.Authorization = `Token ${token}`
        return config;
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
http.interceptors.response.use(responseHandler, responseErrorHandler)

export default {
    ...http
}