import requests from '@/requests/requests.js'
import {mapBaseStates, mapBaseGetters, mapBaseMutations, mapBaseActions} from '../base'
const STATUS_MAP = {0:'新建',1:'待生成',2:'生成中',4:'暂停',5:'已生成',6:'待审核',10:'可发布',11:'已发布',12:'已退回',99:'生成失败'}

const api = requests.videos.video_artifacts,
      name = 'video_artifact'
export default {
    name: 'video_artifacts',
    state: {
        ...mapBaseStates(),
    },
    getters:{
        ...mapBaseGetters(name),
        videoArtifactStatusMap(){ return STATUS_MAP},
    },
    mutations:{
        ...mapBaseMutations(name),
    },
    actions: {
        ...mapBaseActions(name,api),
        uploadTempVideo: async (context,id) => {
            return await api.uploadTempVideo(id)
        },
        removeTempVideo: async (context,id) => {
            return await api.removeTempVideo(id)
        },
        regenArtifactVideo: async (context,{id,force}) => {
            return await api.regen(id,force)
        },
        updateArtifactStatus: async(context,{id,status}) => {
            return await api.status(id,status)
        },
    }
}