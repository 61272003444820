<template>
<div class="imagepreview" :style="previewStyle" @click="onShow">
</div>
</template>
<script>

export default {
    name: 'ImagePreview',
    props:{
        src: String,
        width: String,
        height: String,
        item: [Object, Number, Array],
        show: Function
    },
    computed:{
        previewStyle(){
            if(!this.src) return ''
            let styles = {
                    'background-image': `url(${this.src})`,
                    'background-repeat': 'no-repeat',
                    'background-position': 'center center',
                    'background-size': 'contain',
                    width: this.width,
                    height: this.height
                },
                raw = []
            for(let key in styles){
                raw.push(`${key}:${styles[key]}`)
            }

            return raw.join(';')
        }
    },
    methods:{
        onShow(){
            if(this.show){
                this.show.call(this.$parent,this.item || this.src)
            }else{
                window.open(this.src)
            }
        }

    }
}
</script>
<style>
    .imagepreview{
        border-radius: 5px;
    }
</style>